

























import {Component, Ref, Vue} from "vue-property-decorator";
import api from "@/api";
import {
  AttachmentHostType, CustomFormType,
  DataDictionaryDtoPagedResultDto,
  DonatedAmountConfigCreateOrUpdateDto,
  DonatedAmountConfigSourceType,
  FundDto,
  FundDtoPagedResultDto,
  FundProjectCreateOrUpdateDto, FundProjectDto, StudentCreateOrUpdateDto, StudentDto,
} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import CustomFormEdit from "@/components/CustomForm/Edit/index.vue";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";

@Component({
  components: {
    CustomFormEdit
  },
})
export default class EditFundProject extends Vue {
  dataId!: number;
  defaultData: FundProjectDto = {};
  btnDisabled = false;

  get getData(): Promise<FundProjectDto> {
    if (this.dataId > 0) return api.fundProject.get({id: this.dataId});
    else return new Promise<FundProjectDto>(resolve => this.defaultData);
  }

  get hostType() {
    return CustomFormType.FundProject;
  }

  get hostTypeFile() {
    return AttachmentHostType.FundProject;
  }

  created() {
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
    }
  }


  handleSave(): void {
    this.btnDisabled = true;
    (this.$refs.customForm as any).validate(async (valid: boolean) => {
      if (valid) {
        const data = (
          this.$refs.customForm as any
        ).getRequestData() as FundProjectCreateOrUpdateDto;
        let fn;
        if (this.dataId) fn = api.fundProject.update;
        else fn = api.fundProject.create;
        data.extensionData = '';
        fn({body: data}).then((res) => {
          this.$message.success("更新成功");
          this.$router.push({
            path: "/fundProject/fundProject",
          });
        });
      } else {
        this.$message.error("请检查表单");
        this.btnDisabled = false;
      }
    });
  }

  cancel() {
    this.$router.back();
  }

}
